import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

interface SessionData {
  lastSoc: {
    value: number;
  };
  lastEnergyImport: {
    value: number;
  };
  lastPowerImport: {
    value: number;
  };
  lastSessionCost: {
    value: number;
  };
  chargeTimeRemaining: {
    value: number;
  };
}

interface ChargingSessionViewProps {
  sessionId: string;
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 2rem;
  max-width: 500px;
  margin: 0 auto;
  background: #1a1a1a;
  color: white;
`;

const Title = styled.h1`
  font-size: 1.75rem;
  margin-bottom: 2rem;
  color: white;
`;

const CostSection = styled.h2`
  font-size: 1.25rem;
  margin-bottom: 1rem;
`;

const DonutContainer = styled.div`
  width: 250px;
  height: 250px;
  margin: 2rem auto;
  position: relative;
`;

const Donut = styled.div<{ percentage: number }>`
  width: 100%;
  height: 100%;
  position: relative;
  border-radius: 50%;
  background: conic-gradient(
    #1a73e8 ${props => props.percentage}%,
    #263242 ${props => props.percentage}%
  );
`;

const DonutCenter = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: #1a1a1a;
  border-radius: 50%;
  width: 75%;
  height: 75%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const CurrentCost = styled.div`
  font-size: 2.5rem;
  font-weight: bold;
`;

const CurrentCostLabel = styled.div`
  color: #4285f4;
  font-size: 1rem;
`;

const TimeRemaining = styled.div`
  text-align: center;
  margin-bottom: 2rem;
`;

const TimeValue = styled.div`
  font-size: 1.5rem;
  font-weight: bold;
`;

const TimeLabel = styled.div`
  color: #5f6368;
`;

const InfoGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr auto;
  gap: 1rem;
  margin-bottom: 1rem;
`;

const InfoRow = styled.div`
  display: contents;
  & > * {
    padding: 0.75rem 0;
    border-bottom: 1px solid #333;
  }
`;

const Label = styled.div`
  color: #999;
`;

const Value = styled.div`
  text-align: right;
  font-weight: 500;
`;

const EditButton = styled.button`
  color: #4285f4;
  background: none;
  border: none;
  padding: 1rem 0;
  cursor: pointer;
  text-align: center;
  width: 100%;
  font-weight: 500;
`;

const StopButton = styled.button`
  background: #ea4335;
  color: white;
  border: none;
  border-radius: 4px;
  padding: 1rem;
  width: 100%;
  font-weight: 500;
  cursor: pointer;
  margin-top: 1rem;
`;

const ChargingSessionView: React.FC<ChargingSessionViewProps> = ({ sessionId }) => {
  const [sessionData, setSessionData] = useState<SessionData | null>(null);
  const [isStoppingSession, setIsStoppingSession] = useState(false);

  useEffect(() => {
    const pollSession = async () => {
      try {
        const response = await fetch(`https://2ufjtpakn2.execute-api.us-east-1.amazonaws.com/prod/api/ocpi/cpo/2.2.1/sessions/${sessionId}`);
        const data = await response.json();
        setSessionData(data);
      } catch (error) {
        console.error('Error fetching session data:', error);
      }
    };

    pollSession();
    const interval = setInterval(pollSession, 15000);
    return () => clearInterval(interval);
  }, [sessionId]);

  const handleStopSession = async () => {
    setIsStoppingSession(true);
    try {
      const response = await fetch(
        'https://2ufjtpakn2.execute-api.us-east-1.amazonaws.com/prod/api/ocpi/cpo/2.2.1/commands/stop_session',
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ transactionId: sessionId }),
        }
      );
      
      if (!response.ok) {
        throw new Error('Failed to stop session');
      }
    } catch (error) {
      console.error('Error stopping session:', error);
    } finally {
      setIsStoppingSession(false);
    }
  };

  if (!sessionData) return <div>Loading...</div>;

  const currentCost = sessionData.lastSessionCost.value;
  const socPercentage = sessionData.lastSoc.value;
  const energyAdded = sessionData.lastEnergyImport.value;
  const pricePerKWh = currentCost / energyAdded;

  return (
    <Container>
      <Title>Charge in Progress</Title>
      
      <DonutContainer>
        <Donut percentage={socPercentage}>
          <DonutCenter>
            <CurrentCost>{socPercentage}%</CurrentCost>
            <CurrentCostLabel>State of Charge</CurrentCostLabel>
          </DonutCenter>
        </Donut>
      </DonutContainer>

      {/* <TimeRemaining>
        <TimeValue>{sessionData.chargeTimeRemaining.value} minutes</TimeValue>
        <TimeLabel>Time to reach full charge</TimeLabel>
      </TimeRemaining> */}

      <InfoGrid>
        <InfoRow>
          <Label>Energy added</Label>
          <Value>{energyAdded.toFixed(2)} kWh</Value>
        </InfoRow>
        <InfoRow>
          <Label>Current power</Label>
          <Value>{sessionData.lastPowerImport.value.toFixed(1)} kW</Value>
        </InfoRow>
        <InfoRow>
          <Label>Current cost</Label>
          <Value>${sessionData.lastSessionCost.value.toFixed(2)}</Value>
        </InfoRow>
      </InfoGrid>

      <StopButton 
        onClick={handleStopSession} 
        disabled={isStoppingSession}
      >
        {isStoppingSession ? 'Stopping...' : 'Stop'}
      </StopButton>
    </Container>
  );
};

export default ChargingSessionView; 