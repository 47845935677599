export const darkTheme = {
  colors: {
    background: '#121212',     // Material dark background
    surface: '#1E1E1E',        // Slightly lighter surface elements
    primary: '#90CAF9',        // Lighter blue for better visibility
    text: {
      primary: '#FFFFFF',      // White text
      secondary: '#B3B3B3',    // Light gray for secondary text
    }
  }
}; 