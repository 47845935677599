import React, { useRef, useEffect, useState } from 'react';
import mapboxgl from 'mapbox-gl';
import { useSpring, animated } from 'react-spring';
import { useDrag } from '@use-gesture/react';
import styled from 'styled-components';
import 'mapbox-gl/dist/mapbox-gl.css';
import { BrowserRouter, Routes, Route, useNavigate, Navigate, useParams } from 'react-router-dom';
import ChargingStationDetail from './components/ChargingStationDetail';
import { LocationResponse, Location } from './types';
import { ThemeProvider } from 'styled-components';
import { darkTheme } from './theme';
import ChargingSessionView from './components/ChargingSessionView';

// Store this in your environment variables
mapboxgl.accessToken = 'pk.eyJ1Ijoic3lub3BhbmRyZXciLCJhIjoiY2txZzFnd2JnMWF5MDJwbnpjcXNrYjBhbCJ9.4DS2cxbRoL4JcfMMq7SNuQ';

// Add a theme object for consistent colors
const theme = {
  colors: {
    background: '#1a1a1a',
    surface: '#2d2d2d',
    primary: '#0066ff',
    text: {
      primary: '#ffffff',
      secondary: '#a0a0a0',
      placeholder: '#666666'
    },
    border: '#404040',
    dragHandle: '#404040'
  }
};

const AppWrapper: React.FC = () => {
  const [selectedLocation, setSelectedLocation] = useState<Location | null>(null);
  
  return (
    <ThemeProvider theme={darkTheme}>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<App setSelectedLocation={setSelectedLocation} />} />
          <Route 
            path="/station/:id" 
            element={selectedLocation ? (
              <ChargingStationDetail station={selectedLocation} />
            ) : (
              <Navigate to="/" replace />
            )} 
          />
          <Route path="/charging-session/:sessionId" element={<ChargingSessionWrapper />} />
        </Routes>
      </BrowserRouter>
    </ThemeProvider>
  );
};

// Update App component props
const App: React.FC<{ setSelectedLocation: (location: Location | null) => void }> = ({ setSelectedLocation }) => {
  const mapContainer = useRef<HTMLDivElement>(null);
  const map = useRef<mapboxgl.Map | null>(null);
  const [userLocation, setUserLocation] = useState<[number, number]>([0, 0]);

  // Add state to track if location is loaded
  const [locationLoaded, setLocationLoaded] = useState(false);

  // Bottom sheet states
  const [{ y }, api] = useSpring(() => ({ y: 0 }));
  const [open, setOpen] = useState(false);

  // Add useState for locations
  const [locations, setLocations] = useState<LocationResponse | null>(null);

  // Add useEffect for fetching locations
  useEffect(() => {
    const fetchLocations = async () => {
      try {
        const response = await fetch('https://2ufjtpakn2.execute-api.us-east-1.amazonaws.com/prod/api/ocpi/cpo/2.2.1/locations');
        const data = await response.json();
        setLocations(data);
      } catch (error) {
        console.error('Error fetching locations:', error);
      }
    };

    fetchLocations();
  }, []);

  // Define height for different states
  const closedHeight = 200;  // Height when closed (showing preview)
  const openHeight = window.innerHeight * 0.8;  // Height when fully open

  // Drag binding for bottom sheet
  const bind = useDrag(({ movement: [_, my], down, cancel }) => {
    const currentY = down ? my : 0;
    const shouldOpen = my < -50;  // Open if dragged up more than 50px
    
    if (!down) {
      setOpen(shouldOpen);
    }

    api.start({
      y: down ? currentY : shouldOpen ? -(openHeight - closedHeight) : 0,
      immediate: down
    });
  });

  useEffect(() => {
    // Get user's location first
    navigator.geolocation.getCurrentPosition(
      (position) => {
        const { longitude, latitude } = position.coords;
        setUserLocation([longitude, latitude]);
        setLocationLoaded(true);
      },
      (error) => {
        console.error('Error getting location:', error);
        // Default to New York if location access is denied
        setUserLocation([-74.5, 40]);
        setLocationLoaded(true);
      },
      {
        enableHighAccuracy: true,
        timeout: 5000,
        maximumAge: 0
      }
    );
  }, []);

  useEffect(() => {
    if (!mapContainer.current || !locationLoaded) return;

    // Initialize map with user location
    map.current = new mapboxgl.Map({
      container: mapContainer.current,
      style: 'mapbox://styles/mapbox/dark-v11',
      center: userLocation,
      zoom: 14
    });

    // Add navigation controls
    map.current.addControl(new mapboxgl.NavigationControl(), 'top-right');

    // Add geolocate control
    const geolocateControl = new mapboxgl.GeolocateControl({
      positionOptions: {
        enableHighAccuracy: true
      },
      trackUserLocation: true,
      showUserHeading: true
    });

    map.current.addControl(geolocateControl, 'top-right');

    // Add user location marker
    const marker = new mapboxgl.Marker({
      color: theme.colors.primary
    })
      .setLngLat(userLocation)
      .addTo(map.current);

    // Watch for location updates
    const watchId = navigator.geolocation.watchPosition(
      (position) => {
        const { longitude, latitude } = position.coords;
        const newLocation: [number, number] = [longitude, latitude];
        setUserLocation(newLocation);
        marker.setLngLat(newLocation);
        
        // Optionally center map on location update
        map.current?.easeTo({
          center: newLocation,
          duration: 1000
        });
      },
      (error) => console.error('Error watching location:', error),
      {
        enableHighAccuracy: true,
        timeout: 5000,
        maximumAge: 0
      }
    );

    // Cleanup function
    return () => {
      navigator.geolocation.clearWatch(watchId);
      map.current?.remove();
    };
  }, [locationLoaded]); // Only run when location is loaded

  // Add a function to recenter the map
  const centerOnUser = () => {
    if (map.current) {
      map.current.easeTo({
        center: userLocation,
        zoom: 14,
        duration: 1000
      });
    }
  };

  const navigate = useNavigate();

  const handleStationClick = (station: Location) => {
    setSelectedLocation(station);
    navigate(`/station/${station.id}`);
  };

  const [searchQuery, setSearchQuery] = useState('');
  const [showResults, setShowResults] = useState(false);

  const filteredLocations = locations?.data.filter(location =>
    location.name.toLowerCase().includes(searchQuery.toLowerCase())
  );

  const handleLocationSelect = (locationId: string) => {
    setSearchQuery('');
    setShowResults(false);
    navigate(`/station/${locationId}`);
  };

  return (
    <Container>
      <MapContainer ref={mapContainer} />
      <SearchBar>
        <input
          type="text"
          placeholder="Search..."
          value={searchQuery}
          onChange={(e) => {
            setSearchQuery(e.target.value);
            setShowResults(true);
          }}
          onFocus={() => setShowResults(true)}
        />
        {showResults && searchQuery && (
          <ResultsList>
            {(filteredLocations || []).map(location => (
              <ResultItem
                key={location.id}
                onClick={() => handleLocationSelect(location.id)}
              >
                {location.name}
              </ResultItem>
            ))}
          </ResultsList>
        )}
      </SearchBar>
      <RecenterButton onClick={centerOnUser}>
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2">
          <path d="M12 8c-2.21 0-4 1.79-4 4s1.79 4 4 4 4-1.79 4-4-1.79-4-4-4zm8.94 3A8.994 8.994 0 0013 3.06V1h-2v2.06A8.994 8.994 0 003.06 11H1v2h2.06A8.994 8.994 0 0011 20.94V23h2v-2.06A8.994 8.994 0 0020.94 13H23v-2h-2.06zM12 19c-3.87 0-7-3.13-7-7s3.13-7 7-7 7 3.13 7 7-3.13 7-7 7z"/>
        </svg>
      </RecenterButton>
      <CardsContainer>
        {locations?.data.map(station => (
          <StationCard 
            key={station.id}
            onClick={() => handleStationClick(station)}
          >
            <CardHeader>
              <StationName>{station.name}</StationName>
              <RecommendedBadge>Recommended</RecommendedBadge>
            </CardHeader>
            <CardDetails>
              <DetailItem>
                <PriceIcon>$</PriceIcon>
                {station.currentPrice.price}/ kWh
              </DetailItem>
              <DetailItem>
                <AvailabilityIcon>⚡</AvailabilityIcon>
                {station.availableConnectors} / {station.totalConnectors}
              </DetailItem>
              {/* <DetailItem>
                <ETAIcon>⏱</ETAIcon>
                {23}
              </DetailItem> */}
            </CardDetails>
          </StationCard>
        ))}
      </CardsContainer>
    </Container>
  );
};

// Updated styled components with dark theme
const Container = styled.div`
  position: relative;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  background: ${theme.colors.background};
  color: ${theme.colors.text.primary};
`;

const MapContainer = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
`;


const SearchBar = styled.div`
  position: relative;
  padding: 16px;
  background: ${props => props.theme.colors.surface};
  border-radius: 8px;
  margin: 16px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);

  input {
    width: 100%;
    background: none;
    border: none;
    font-size: 16px;
    color: ${props => props.theme.colors.text.primary};
    outline: none;

    &::placeholder {
      color: ${props => props.theme.colors.text.secondary};
    }
  }
`;

const ResultsList = styled.div`
  position: absolute;
  top: calc(100% + 4px);
  left: 0;
  right: 0;
  background: ${props => props.theme.colors.surface};
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  margin: 0 16px;
  max-height: 300px;
  overflow-y: auto;
  z-index: 1000;
`;

const ResultItem = styled.div`
  padding: 16px;
  cursor: pointer;
  color: ${props => props.theme.colors.text.primary};

  &:hover {
    background: ${props => props.theme.colors.hover};
  }

  &:not(:last-child) {
    border-bottom: 1px solid ${props => props.theme.colors.border};
  }
`;

// Add styled component for the recenter button
const RecenterButton = styled.button`
  position: absolute;
  right: 20px;
  top: 100px; // Position below the navigation controls
  width: 40px;
  height: 40px;
  border-radius: 8px;
  background: ${theme.colors.surface};
  border: 1px solid ${theme.colors.border};
  color: ${theme.colors.text.primary};
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  z-index: 1;

  &:hover {
    background: ${theme.colors.background};
  }

  svg {
    width: 20px;
    height: 20px;
  }
`;

const CardsContainer = styled.div`
  position: absolute;
  bottom: 20px;
  left: 0;
  right: 0;
  padding: 0 20px;
  display: flex;
  gap: 16px;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
  scrollbar-width: none;
  
  &::-webkit-scrollbar {
    display: none;
  }
`;

const StationCard = styled.div`
  flex: 0 0 auto;
  width: 280px;
  background: ${theme.colors.surface};
  border-radius: 12px;
  padding: 16px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.2);
  cursor: pointer;
`;

const CardHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 12px;
`;

const StationName = styled.h3`
  margin: 0;
  font-size: 18px;
  color: ${theme.colors.text.primary};
`;

const RecommendedBadge = styled.span`
  background: #00875A;
  color: white;
  padding: 4px 8px;
  border-radius: 4px;
  font-size: 12px;
`;

const CardDetails = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const DetailItem = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  color: ${theme.colors.text.secondary};
  font-size: 14px;
`;

const IconStyle = styled.span`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 20px;
  height: 20px;
`;

const PriceIcon = styled(IconStyle)``;
const AvailabilityIcon = styled(IconStyle)``;
const ETAIcon = styled(IconStyle)``;

// Create a wrapper component to handle the params
const ChargingSessionWrapper = () => {
  const { sessionId } = useParams();
  return <ChargingSessionView sessionId={sessionId!} />;
};

export default AppWrapper; 