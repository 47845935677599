import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import { Connector, Evse, Location } from '../types';
import { loadStripe } from '@stripe/stripe-js';
import {
  PaymentElement,
  Elements,
  CardElement,
  useStripe,
  useElements
} from '@stripe/react-stripe-js';

interface ChargingStationDetailProps {
  station: Location;
}

// Initialize Stripe (put this outside the component)

// Create a Payment Sheet component
const PaymentSheet: React.FC<{ 
  station: Location; 
  onSuccess: (response: { id: string }) => void; 
  onCancel: () => void;
  selectedConnector: Connector;
  selectedCharger: Evse | null;
}> = ({ station, onSuccess, onCancel, selectedConnector, selectedCharger }) => {
  const [clientSecret, setClientSecret] = useState<string>();
  const [accountId, setAccountId] = useState<string>();
  const [setupIntentId, setSetupIntentId] = useState<string>();

  useEffect(() => {
    // Fetch client secret when component mounts
    fetch('https://2ufjtpakn2.execute-api.us-east-1.amazonaws.com/prod/api/authorize-public-session', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        siteId: station.id
      })
    })
      .then(res => res.json())
      .then(data => {
        setClientSecret(data.clientSecret);
        setAccountId(data.id);
        setSetupIntentId(data.intentId);
      });
  }, [station.id]);

  if (!clientSecret || !accountId) return null;

  const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY ?? 'pk_live_51JKB4QCbK1IBy8Wwto7yS1lWKGOSnLrlbIqRKywSyulikbnZHqaKngERmcYAH1zdaTQOUAJIcchhsZoo3WVKzxfD00AjWyb58U',
    {
      stripeAccount: accountId
    }
  );
  return (
    <Elements stripe={stripePromise} options={{ 
      clientSecret,
      appearance: {
        theme: 'night',
        variables: {
          colorPrimary: '#2196F3', // Your primary color
          colorBackground: '#1E1E1E', // Dark background
          colorText: '#FFFFFF',
          colorDanger: '#FF5252',
          fontFamily: '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, sans-serif',
          borderRadius: '8px',
          spacingUnit: '4px',
        },
        rules: {
          '.Input': {
            border: '1px solid #424242',
            padding: '12px',
          },
          '.Input:focus': {
            border: '1px solid #2196F3',
          },
          '.Label': {
            color: '#9E9E9E',
          }
        }
      },
    }}>
      <PaymentForm 
        onSuccess={onSuccess} 
        onCancel={onCancel}
        selectedConnector={selectedConnector}
        selectedCharger={selectedCharger}
        setupIntentId={setupIntentId || ''}
        accountId={accountId || ''}
        station={station}
      />
    </Elements>
  );
};

// Separate the form into its own component
const PaymentForm: React.FC<{ 
  onSuccess: (response: { id: string }) => void; 
  onCancel: () => void;
  selectedConnector: Connector;
  selectedCharger: Evse | null;
  setupIntentId: string;
  station: Location;
  accountId: string;
}> = ({ onSuccess, onCancel, selectedConnector, selectedCharger, setupIntentId, accountId, station }) => {
  const stripe = useStripe();
  const elements = useElements();
  const [isProcessing, setIsProcessing] = useState(false);
  const [error, setError] = useState<string>();
  const [isReady, setIsReady] = useState(false);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!stripe || !elements) return;

    setIsProcessing(true);
    setError(undefined);

    try {
      const result = await stripe.confirmSetup({
        elements,
        confirmParams: {
          return_url: `${window.location.origin}/charging-session`,
          payment_method_data: {
            billing_details: {}
          }
        },
        redirect: 'if_required'
      });

      if (result.error) {
        setError(result.error.message);
        return;
      }

      // Store the confirmed setupIntent ID for the session start
      const confirmedSetupIntentId = result.setupIntent.id;
      
      const sessionResponse = await fetch('https://2ufjtpakn2.execute-api.us-east-1.amazonaws.com/prod/api/ocpi/cpo/2.2.1/commands/start_session', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          setupIntentId: confirmedSetupIntentId,
          connectorId: selectedConnector.connectorId,
          chargerId: selectedCharger?.evseId,
          authTag: selectedCharger?.evseId + '-PUBLIC',
        })
      });

      if (!sessionResponse.ok) {
        throw new Error('Failed to start charging session');
      }

      const sessionData = await sessionResponse.json();
      onSuccess(sessionData);
    } catch (err) {
      setError(typeof err === 'string' ? err : 'Failed to start charging session');
    } finally {
      setIsProcessing(false);
    }
  };

  const handleReady = () => {
    setIsReady(true);
  };

  return (
    <form onSubmit={handleSubmit}>
      <CardContainer>
        <PaymentElement onReady={handleReady} />
      </CardContainer>

      {error && <ErrorMessage>{error}</ErrorMessage>}

      <ButtonGroup>
        <Button variant="secondary" onClick={onCancel} disabled={isProcessing}>
          Cancel
        </Button>
        <Button 
          variant="primary" 
          type="submit"
          disabled={!stripe || !elements || isProcessing || !isReady}
        >
          {isProcessing ? 'Starting Session...' : 'Start Charging'}
        </Button>
      </ButtonGroup>
    </form>
  );
};

const ChargingStationDetail: React.FC<ChargingStationDetailProps> = ({ station }) => {
  const navigate = useNavigate();
  const [selectedEvse, setSelectedEvse] = useState<Evse | null>(null);
  const [selectedConnector, setSelectedConnector] = useState<Connector | null>(null);

  const handleSuccess = (response: { id: string }) => {
    navigate(`/charging-session/${response.id}`);
  };

  return (
    <Container>
      <Header>
        <BackButton onClick={() => navigate(-1)}>
          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor">
            <path d="M15 18l-6-6 6-6" strokeWidth="2" strokeLinecap="round"/>
          </svg>
        </BackButton>
        {/* <Actions>
          <ActionButton>
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor">
              <path d="M12 21l-1.45-1.3C5.4 15.36 2 12.28 2 8.5 2 5.42 4.42 3 7.5 3c1.74 0 3.41.81 4.5 2.09C13.09 3.81 14.76 3 16.5 3 19.58 3 22 5.42 22 8.5c0 3.78-3.4 6.86-8.55 11.54L12 21z"/>
            </svg>
          </ActionButton>
          <ActionButton>
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor">
              <path d="M17 3H7c-1.1 0-2 .9-2 2v16l7-3 7 3V5c0-1.1-.9-2-2-2z"/>
            </svg>
          </ActionButton>
        </Actions> */}
      </Header>

      <Content>
        <Title>{station.name}</Title>
        <Network>
          <NetworkIcon>⚡</NetworkIcon>
          {station.operator.name}
        </Network>
        
        <Address>{station.address}</Address>
        
        <InfoSection>
          <InfoItem>
            <InfoIcon>🕒</InfoIcon>
            <div>
              <InfoLabel>Open</InfoLabel>
              <InfoValue>24/7</InfoValue>
            </div>
          </InfoItem>
          
          <InfoItem>
            <InfoIcon>💰</InfoIcon>
            <div>
              <InfoLabel>Price now</InfoLabel>
              <InfoValue>{station.currentPrice.price}/ kWh</InfoValue>
              {/* {station.parkingNote && (
                <ParkingNote>{station.parkingNote}</ParkingNote>
              )} */}
            </div>
          </InfoItem>
        </InfoSection>

        {/* <TabContainer>
          <Tab active>Chargers</Tab>
          <Tab>Prices</Tab>
        </TabContainer> */}

        <ChargersList>
          {station.evses.map((evse) => (
            <ChargerItem key={evse.evseId}>
              <ChargerHeader>
                <ChargerID>{evse.evseName}</ChargerID>
                <div>
                    <InfoValue>{station.currentPrice.price}/ kWh</InfoValue>
                    {/* {station.parkingNote && (
                        <ParkingNote>{station.parkingNote}</ParkingNote>
                    )} */}
                </div>
              </ChargerHeader>
              
              <ConnectorsList>
                {evse.connectors.map((connector) => (
                  <ConnectorRow 
                    key={connector.connectorId}
                    onClick={() => {
                      setSelectedConnector(connector);
                      setSelectedEvse(evse);
                    }}
                  >
                    <ConnectorName>{connector.connectorName ? connector.connectorName : 'Connector ' + connector.connectorId}</ConnectorName>
                    <StatusBadge status={connector.status}>
                      {connector.status.toLowerCase()}
                    </StatusBadge>
                  </ConnectorRow>
                ))}
              </ConnectorsList>
            </ChargerItem>
          ))}
        </ChargersList>

        <ReportIssue>Report issue</ReportIssue>
      </Content>

      {selectedConnector && (
        <BottomSheet onClose={() => {
          setSelectedConnector(null);
          setSelectedEvse(null);
        }}>
          <SheetContent>
            <h3>{selectedConnector.connectorName}</h3>
            <p>Connector: {selectedConnector.connectorName ? selectedConnector.connectorName : selectedConnector.connectorId }</p>
            <PaymentSheet 
              station={station}
              selectedConnector={selectedConnector}
              selectedCharger={selectedEvse}
              onSuccess={handleSuccess}
              onCancel={() => setSelectedConnector(null)}
            />
          </SheetContent>
        </BottomSheet>
      )}
    </Container>
  );
};

const Container = styled.div`
  background: ${props => props.theme.colors.background};
  min-height: 100vh;
  color: ${props => props.theme.colors.text.primary};
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px;
`;

const BackButton = styled.button`
  background: none;
  border: none;
  color: ${props => props.theme.colors.text.primary};
  padding: 8px;
  cursor: pointer;
`;

const Actions = styled.div`
  display: flex;
  gap: 16px;
`;

const ActionButton = styled.button`
  background: none;
  border: none;
  color: ${props => props.theme.colors.text.primary};
  padding: 8px;
  cursor: pointer;
`;

const Content = styled.div`
  padding: 0 16px;
`;

const Title = styled.h1`
  font-size: 24px;
  font-weight: 600;
  margin-bottom: 8px;
`;

const Network = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  margin-bottom: 16px;
`;

const NetworkIcon = styled.span`
  font-size: 20px;
`;

const Address = styled.div`
  margin-bottom: 16px;
  color: ${props => props.theme.colors.text.secondary};
`;

const InfoSection = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin-bottom: 24px;
`;

const InfoItem = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 12px;
`;

const InfoIcon = styled.span`
  font-size: 20px;
`;

const InfoLabel = styled.div`
  color: ${props => props.theme.colors.text.secondary};
  font-size: 14px;
`;

const InfoValue = styled.div`
  font-weight: 500;
`;

const ParkingNote = styled.div`
  color: ${props => props.theme.colors.text.secondary};
  font-size: 14px;
  margin-top: 4px;
`;

const TabContainer = styled.div`
  display: flex;
  gap: 16px;
  margin-bottom: 16px;
`;

const Tab = styled.button<{ active?: boolean }>`
  padding: 8px 16px;
  border: none;
  background: ${props => props.active ? props.theme.colors.primary : 'transparent'};
  color: ${props => props.active ? 'white' : props.theme.colors.text.primary};
  border-radius: 20px;
  cursor: pointer;
`;

const ChargersList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin-bottom: 24px;
`;

const ChargerItem = styled.div`
  background: ${props => props.theme.colors.surface};
  padding: 16px;
  border-radius: 8px;
`;

const ChargerHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 16px;
`;

const ChargerID = styled.div`
  font-weight: 500;
  color: ${props => props.theme.colors.text.secondary};
  margin-right: 16px;
`;

const StatusBadge = styled.div<{ status: string }>`
  padding: 4px 8px;
  border-radius: 12px;
  font-size: 12px;
  background: ${props => {
    switch (props.status.toUpperCase()) {
      case 'AVAILABLE':
        return '#2196F3'; // Nice blue
      case 'CHARGING':
        return '#424242'; // Dark grey
      default:
        return '#FF5252'; // Red for errors/unavailable
    }
  }};
  color: white;
`;

const ReportIssue = styled.button`
  width: 100%;
  padding: 12px;
  background: none;
  border: 1px solid ${props => props.theme.colors.text.secondary};
  border-radius: 8px;
  color: ${props => props.theme.colors.text.primary};
  cursor: pointer;
`;

const ConnectorsList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const ConnectorRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 24px;
`;

const ConnectorName = styled.div`
  font-weight: 500;
  color: ${props => props.theme.colors.text.secondary};
  margin-right: 24px;
`;

const BottomSheet = styled.div<{ isOpen?: boolean; onClose?: () => void }>`
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  background: ${props => props.theme.colors.surface};
  border-radius: 16px 16px 0 0;
  padding: 20px;
  animation: slideUp 0.3s ease-out;
  z-index: 1000;

  @keyframes slideUp {
    from { transform: translateY(100%); }
    to { transform: translateY(0); }
  }
`;

const SheetContent = styled.div`
  max-height: 80vh;
  overflow-y: auto;
  
  h3 {
    margin-bottom: 16px;
  }
`;

const ButtonGroup = styled.div`
  display: flex;
  gap: 12px;
  margin-top: 24px;
`;

const Button = styled.button<{ variant?: 'primary' | 'secondary' }>`
  flex: 1;
  padding: 12px;
  border-radius: 8px;
  font-weight: 500;
  cursor: pointer;
  
  ${props => props.variant === 'primary' ? `
    background: ${props.theme.colors.primary};
    color: white;
    border: none;
  ` : `
    background: transparent;
    color: ${props.theme.colors.text.primary};
    border: 1px solid ${props.theme.colors.text.secondary};
  `}
`;

const CardContainer = styled.div`
  padding: 12px;
  border: 1px solid ${props => props.theme.colors.border};
  border-radius: 8px;
  margin-bottom: 24px;
`;

const Divider = styled.div`
  text-align: center;
  margin: 24px 0;
  color: ${props => props.theme.colors.text.secondary};
  position: relative;
  
  &::before,
  &::after {
    content: '';
    position: absolute;
    top: 50%;
    width: 45%;
    height: 1px;
    background: ${props => props.theme.colors.border};
  }
  
  &::before { left: 0; }
  &::after { right: 0; }
`;

const ErrorMessage = styled.div`
  color: ${props => props.theme.colors.error || '#FF5252'};
  margin: 12px 0;
  font-size: 14px;
`;

// ... more styled components for other elements ...

export default ChargingStationDetail; 